<script setup>
import UserPhoto from '@/components/round-thumb/ThumbOne'
import { ref } from 'vue'

let menuActive = ref(false)

function activateMenu() {
    menuActive.value = !menuActive.value
}
</script>

<template>
    <div class="user-dropdown">
        <div class="parent" @click="activateMenu">
            <UserPhoto />
            <span class="user-name">Mr. Azim</span>
            <font-awesome-icon :icon="['fas', 'fa-caret-down']" style="color: black" />
        </div>
        <div class="child" v-if="menuActive == true">
            logout 
        </div>
    </div>
</template>

<style>
.user-dropdown > .parent {
    height: 100%;
    overflow: hidden;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
    cursor: pointer;
}

.user-dropdown > .child {
    width: 200px;
    background-color: rgb(231, 231, 231);
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.user-name {
    font-size: 14px;
    font-weight: bold;
}
</style>