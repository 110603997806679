<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useCommonStore } from '@/stores/common'
import { useRouter } from 'vue-router'

const commonStore = useCommonStore()
const router = useRouter()

function onMenuClick(route) {
   if(router.currentRoute.value.path.split('/')[1] != route.split('/')[1]) {
      commonStore.loading = true
      router.push(route)
   } 

   commonStore.loading = false
}

onMounted(() => {
   commonStore.loading = false
})

onUnmounted(() => {
   commonStore.loading = true
})
</script>

<template>
    <div class="navmenu">
        <nav>
            <menu>
              <menuitem><a @click="onMenuClick('/')" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-home']" /> Home</a></menuitem>
              <!-- <menuitem id="demo1">
                  <a class="menu-item"><font-awesome-icon :icon="['fas', 'fa-moon']" /> Services <font-awesome-icon :icon="['fas', 'fa-caret-down']" /></a>
                  <menu>
                     <menuitem>
                           <a class="menu-item" @click="onMenuClick('')">Hospitalization</a>
                           <a class="menu-item" @click="onMenuClick('')">Doctor</a>
                           <a class="menu-item" @click="onMenuClick('')">Emergency</a>
                           <a class="menu-item" @click="onMenuClick('')">Blood Donor</a>
                           <a class="menu-item" @click="onMenuClick('')">Ambulance</a>
                           <a class="menu-item" @click="onMenuClick('')">Treatment</a>
                           <a class="menu-item" @click="onMenuClick('')">Testing and Diagnost</a>
                           <a class="menu-item" @click="onMenuClick('')">Nurse</a>
                           <a class="menu-item" @click="onMenuClick('')">Caregiver</a>
                           <a class="menu-item" @click="onMenuClick('')">Health Insurance</a>
                     </menuitem>
                  </menu>
              </menuitem>  -->
              <!-- <menuitem id="demo1">
                  <a class="menu-item"><font-awesome-icon :icon="['fas', 'fa-gear']" /> Training and Certifications <font-awesome-icon :icon="['fas', 'fa-caret-down']" /></a>
                  <menu>
                     <menuitem>
                           <a class="menu-item" @click="onMenuClick('')">Caregiver</a>
                           <a class="menu-item" @click="onMenuClick('')">Nursing</a>
                     </menuitem>
                  </menu>
              </menuitem>  -->
              <menuitem><a href="https://ehaspatal.com/#services" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-moon']" /> Services</a></menuitem>
              <menuitem><a href="https://ehaspatal.com/#training" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-gear']" /> Training and Certifications</a></menuitem>
              <!-- <menuitem><a @click="onMenuClick('/about-us')" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-layer-group']" /> About us</a></menuitem>
              <menuitem><a @click="onMenuClick('/about-us')" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-paper-plane']" /> Contact</a></menuitem> -->
            </menu>
        </nav>
    </div>
</template>

<style scoped>


.active-menu {
    color: #0FA3B1 !important; 
}
.navmenu {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-item {
    text-decoration: none;
    font-weight: bold;
}

.menu-item:hover {
    color: #0FA3B1 !important; 
}

nav menuitem {
    position:relative;
    display:block;
    opacity:0;
    cursor:pointer;
 }
 
 nav menuitem > menu {
    position: absolute;
    pointer-events:none;
 }
 nav > menu { 
    display:flex; 
    justify-content: end;
    gap: 10px;
}
 
 nav > menu > menuitem { pointer-events: all; opacity:1; }
 menu menuitem .menu-item { 
    white-space:nowrap; 
    display:block; 
}

 nav > menu > menuitem > .menu-item {
    text-align: center;
}
    
 menuitem:hover > menu {
    pointer-events:initial;
 }
 menuitem:hover > menu > menuitem,
 menu:hover > menuitem{
    opacity:1;
 }
 nav > menu > menuitem menuitem menu {
    transform:translateX(100%);
    top:0; right:0;
 }
 
 nav .menu-item {
    /* background:#0FA3B1; */
    color: inherit;
    transition: background 0.5s, color 0.5s, transform 0.5s;
    
    padding:5px;
    font-size: 13px;
    box-sizing:border-box;
    border-radius:3px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); */
    position:relative;
 }

 nav menuitem menu menuitem {
    background: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 99999;
 }
 
 nav .menu-item:hover:before {
    content: '';
    top:0;left:0;
    position:absolute;
    width:100%;
    height:100%;
 }

 nav menuitem > menu{ 
    padding: 0;
    margin-top: 0;
 }
 
 nav > menu > menuitem > menu > menuitem{
    transition: transform 0.6s, opacity 0.6s;
    transform:translateY(150%);
    opacity:0;
    padding: 10px;
 }
 nav > menu > menuitem:hover > menu > menuitem,
 nav > menu > menuitem.hover > menu > menuitem{
    transform:translateY(0%);
    opacity: 1;
 }
 
 menuitem > menu > menuitem > menu > menuitem{
    transition: transform 0.6s, opacity 0.6s;
    transform:translateX(195px) translateY(0%);
    opacity: 0;
 } 
 menuitem > menu > menuitem:hover > menu > menuitem,  
 menuitem > menu > menuitem.hover > menu > menuitem{  
    transform:translateX(0) translateY(0%);
    opacity: 1;
 }

 @media only screen and (max-width: 600px) {
    .navmenu {
       display: none;
    }
}
</style>