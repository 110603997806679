import { defineStore } from 'pinia'

export const useCommonStore = defineStore({
    id: 'common',
    state: () => ({
      loading: true
    }),
    actions: {
    
    }
}) 