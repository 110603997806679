<template>
    <div class="user-photo">
       <img src="@/assets/user/profile-pic.png" alt="">
    </div>
</template>

<style>
.user-photo {
    height: 100%;
    overflow: hidden;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
    cursor: pointer;
}

.user-photo img {
    height: 50%;
    border-radius: 50%;
}
</style>