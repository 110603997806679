import { createApp } from 'vue'
import App from './App.vue'
import Routes from './routes/index'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-green/theme.css'
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import FileUpload from 'primevue/fileupload'

import { createPinia } from 'pinia';

library.add(fas, far, fab)
dom.watch();

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(Routes)
.use(PrimeVue)
.component('Tag', Tag)
.component('Dialog', Dialog)
.component('Dropdown', Dropdown)
.component('InputText', InputText)
.component('Password', Password)
.component('Button', Button)
.component('FileUpload', FileUpload)
.use(createPinia())
.mount('#app')
