<script setup>
import Header from '@/layout/organs/HeaderLayout.vue'
import Footer from '@/layout/organs/FooterLayout.vue'
import LaodingComp from '@/components/spinner/RollerComponent.vue'
import { useCommonStore } from '@/stores/common'

const commonStore = useCommonStore()
</script>
<template>
    <div class="loader" v-if="commonStore.loading == true">
        <LaodingComp></LaodingComp>
    </div>
    <Header />
    <div class="content">
        <slot />
    </div>
    <Footer />
</template>

<style scoped>
.content {
    margin-top: 15vh;
    min-height: 75vh;
    float: left;
    width: 100%;
}
</style>