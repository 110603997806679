import { createRouter, createWebHistory } from 'vue-router'

import UserLayout from '@/layout/UserLayout'
import DashboardLayout from '@/layout/DashboardLayout'

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: () => import('@/modules/website/LandingPage'),
        meta: { layout: UserLayout }
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import('@/modules/website/AboutUs'),
        meta: { layout: UserLayout }
    },
    {
        path: '/caregiver/training/apply',
        name: 'CaregiverApplyPage',
        component: () => import('@/modules/website/training/CaregiverApplyPage'),
        meta: { layout: UserLayout }
    },
    {
        path: '/nurse/training/apply',
        name: 'NurseApplyPage',
        component: () => import('@/modules/website/training/NurseApplyPage'),
        meta: { layout: UserLayout }
    },
    // Auth
    {
        path: '/login',
        name: 'LoginPage',
        component: () => import('@/auth/LoginPage'),
        meta: { layout: UserLayout }
    },
    {
        path: '/join',
        name: 'RegistrationPage',
        component: () => import('@/auth/RegistrationPage'),
        meta: { layout: UserLayout }
    },
    // Dashboard
    {
        path: '/dashboard',
        name: 'HomePage',
        component: () => import('@/modules/dashboard/HomePage'),
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/verification',
        name: 'UserVerification',
        component: () => import('@/modules/dashboard/verification/UserVerification'),
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/settings',
        name: 'SettingsPage',
        component: () => import('@/modules/dashboard/SettingsPage'),
        meta: { layout: DashboardLayout }
    },
    {
        path: '/dashboard/my-info',
        name: 'ProfilePage',
        component: () => import('@/modules/dashboard/ProfilePage'),
        meta: { layout: DashboardLayout }
    }

]

var router = createRouter({
    routes,
    history: createWebHistory('/')
})

export default router