<script setup>
import Button from 'primevue/button';
import 'primeicons/primeicons.css'
import { useRouter } from 'vue-router'

const router = useRouter()

function joinUs() {
   router.push('join')
}
</script>

<template>
    <div class="navmenu-responsive">
        <nav>
            <menu>
              <menuitem><router-link to="/" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-home']" /> Home</router-link></menuitem>
              <menuitem><a href="https://ehaspatal.com/#services" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-moon']" /> Services</a></menuitem>
              <menuitem><a href="https://ehaspatal.com/#training" class="menu-item"><font-awesome-icon :icon="['fas', 'fa-gear']" /> Training and Certifications</a></menuitem>
              <!-- <menuitem><a class="menu-item"><font-awesome-icon :icon="['fas', 'fa-layer-group']" /> About us</a></menuitem>
              <menuitem><a class="menu-item"><font-awesome-icon :icon="['fas', 'fa-paper-plane']" /> Contact</a></menuitem> -->
              <Button class="join-us" @click="joinUs" icon="pi pi-user-plus" label="Join with Us" severity="danger" size="small" rounded />
            </menu>
        </nav>
    </div>
</template>

<style scoped>

.join-us {
    width: 200px !important;
}

.navmenu-responsive {
    display: none;
    width: 100%;
    position: absolute;
    z-index: 9;
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.navmenu-responsive > nav > menu {
    left: 0;
    right: 0;
    display: grid;
}

menuitem {
    margin: 5px 0;
}

menuitem > a {
    text-decoration: none !important;
    color: inherit !important;
}

@media only screen and (max-width: 600px) {
    .navmenu-responsive {
        display: block;
    }
}
</style>