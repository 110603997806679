<script setup>
import { ref } from 'vue'
import Menu from '@/components/menu/NavigationMenu.vue'
import ResponsiveMenu from '@/components/menu/ResponsiveMenu.vue'
import Button from 'primevue/button';
import { useRouter } from 'vue-router'
import 'primeicons/primeicons.css'

const route = useRouter()

let displayResponsiveMenu = ref(false)

function displayTrue() {
    displayResponsiveMenu.value = !displayResponsiveMenu.value
}

function joinUs() {
   route.push('/join')
}

</script>
<template>
    <div class="header">
        <div class="container d-flex-space-between-20 align-items-center">
            <router-link to="/" class="logo">
                <img src="@/assets/logo.png" alt="">
            </router-link>
            <Menu />
            <Button class="join-us" @click="joinUs" icon="pi pi-user-plus" label="Join with Us" severity="danger" size="small" rounded />
            <div class="responsive-menu-icon">
                <font-awesome-icon @click="displayTrue" :icon="['fas', 'fa-bars']" />
            </div>
        </div>
        <ResponsiveMenu v-if="displayResponsiveMenu" />
    </div>
</template>

<style scoped>
.header {
    height: 15vh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    position: fixed;
    z-index: 99;
    float: left;
    width: 100%;
}

.header > .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.logo {
    height: 100%;
}

.logo > img {
    height: 100%;
}

.responsive-menu-icon {
    display: none;
    z-index: 0;
}
@media only screen and (max-width: 600px) {
    .responsive-menu-icon {
        display: block;
    }

    .join-us {
        display: none;
    }
}
</style>