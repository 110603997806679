<template>
    <div class="footer">
        <div class="container">
            <div class="brand">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="">
                </div>
                <div class="about-note">
                    <p>eHospital Systems is a Customizable, Comprehensive, 
                        and Integrated Hospital Management System designed to manage all hospital 
                        operations.
                    </p>
                </div>
            </div>
            <div class="links">
                <h3>Medical Services</h3>
    
                <div>
                    <a>ICU</a>
                    <a>CCU</a>
                    <a>Emergency Unit</a>
                    <a>Blood Donor</a>
                    <a>Ambulance</a>
                </div>
            </div>
            <div class="links">
                <h3>Other Services</h3>
    
                <div>
                    <a>Imported Baby Item</a>
                    <a>Organic Food</a>
                    <a>Organic Filter</a>
                    <a>Health Insurance</a>
                    <a>PRP</a>
                </div>
            </div>
            <div class="address">
                <h3>Contact Us</h3>
                <div class="contact">
                    <table>
                        <tr>
                            <td><font-awesome-icon :icon="['fas', 'fa-location-dot']" /></td>
                            <td>Uttara, Dhaka</td>
                        </tr>
                        <tr>
                            <td><font-awesome-icon :icon="['fas', 'fa-envelope']" /></td>
                            <td>contact@ehaspatal.com</td>
                        </tr>
                        <tr>
                            <td><font-awesome-icon :icon="['fas', 'fa-phone']" /></td>
                            <td> (880) 1635860408</td>
                        </tr>
                    </table>
                </div>
                <div class="socials">
                    <a href=""><font-awesome-icon :icon="['fab', 'fa-facebook']" /></a>
                    <a href=""><font-awesome-icon :icon="['fab', 'fa-twitter']" /></a>
                    <a href=""><font-awesome-icon :icon="['fab', 'fa-youtube']" /></a>
                    <a href=""><font-awesome-icon :icon="['fab', 'fa-linkedin']" /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.footer {
    min-height: 10vh;
    background-color: #ededed;
    float: left;
    width: 100%;
}

.footer .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.socials {
    display: flex;
    gap: 20px;
    font-size: 25px;
}

.socials > * {
    cursor: pointer;
    color: black;
}

.socials > *:hover {
    opacity: 0.8;
}

.footer .container > div:not(:last-child) {
    border-right: 1px solid #d3d3d3;
}

.footer .container > div > * {
    margin-bottom: 20px;
}

.links > div {
    display: grid;
    gap: 7px;
    margin: 10px 0;
}

.links > div > * {
    font-weight: bold;
    color: #215f91;
    cursor: pointer;
    font-size: 15px;
}

.links > div > *:hover {
    opacity: 0.8;
}

.logo {
    height: 100px;
}

.logo > img {
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .footer .container {
        grid-template-columns: 1fr;
    }

    .footer .container > div:not(:last-child) {
        border-right: none;
    }
}
</style>