<template>
  <component :is="$route.meta.layout || 'div'">
    <router-view></router-view>
  </component>
</template>

<style>
html {
  scroll-behavior: smooth;
}

body {
  margin: auto;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.container {
  margin: 0 10%;
}

/* spacing */
.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-30 {
  margin: 30px;
}

.py-10 {
  padding: 10px 0;
}

.py-15 {
  padding: 15px 0;
}

.py-20 {
  padding: 20px 0;
}

.py-30 {
  padding: 30px 0;
}

.px-10 {
  padding: 0 10px;
}

.px-15 {
  padding: 0 15px;
}

.px-20 {
  padding: 0 20px;
}

.px-30 {
  padding: 0 30px;
}

.my-10 {
  margin: 10px 0;
}

.my-15 {
  margin: 15px 0;
}

.my-20 {
  margin: 20px 0;
}

.my-30 {
  margin: 30px 0;
}

.mx-10 {
  margin: 0 10px;
}

.mx-15 {
  margin: 0 15px;
}

.mx-20 {
  margin: 0 20px;
}

.mx-30 {
  margin: 0 30px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.cp-x-5 > * {
  padding: 0 5px;
}

.cp-x-10 > * {
  padding: 0 10px;
}

.cp-x-15 > * {
  padding: 0 15px;
}

.cp-x-20 > * {
  padding: 0 20px;
}

.cp-y-5 > * {
  padding: 5px 0;
}

.cp-y-10 > * {
  padding: 10px 0;
}

.cp-y-15 > * {
  padding: 15px 0;
}

.cp-y-20 > * {
  padding: 20px 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-30 {
  margin-left: 30px;
}

/* spacing ends */

/* Buttons */



/* Positioning */
.d-flex-center {
  display: flex;
  justify-content: center;
}

.d-flex-start-10 {
  display: flex;
  gap: 10px;
  justify-content: start;
}

.d-flex-start-20 {
  display: flex;
  gap: 20px;
  justify-content: start;
}

.d-flex-end-10 {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.d-flex-end-20 {
  display: flex;
  gap: 20px;
  justify-content: end;
}

.d-flex-center-10 {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.d-flex-center-20 {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.d-flex-space-between-10 {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.d-flex-space-between-20 {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.d-flex-space-around-10 {
  display: flex;
  gap: 10px;
  justify-content: space-around;
}

.d-flex-space-around-20 {
  display: flex;
  gap: 20px;
  justify-content: space-around;
}

.d-grid-col-2-gap-10 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.d-grid-col-3-gap-10 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.d-grid-col-4-gap-10 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.d-grid-col-4-gap-20 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.d-grid-col-2-gap-20 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.d-grid-col-5-gap-10 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.text-center {
  text-align: center;
}

/* Positioning ends */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
